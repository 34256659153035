import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LoaderIcon } from 'react-hot-toast';

const NetworkTrendBarChart = ({ data, dataType, title, yAxisTitle }) => {
    const [loading, setLoading] = useState(true);
    const [graphData, setGraphData] = useState([]);
    useEffect(() => {
        if (data) {
            let selectedData = [];
            switch (dataType) {
                case 'incidents':
                    selectedData = data.incidents.map(item => ({
                        month: item.month,
                        count: item.incident_closed_count,
                        percentage: item.percentage_of_count,
                    }));
                    break;
                case 'requests':
                    selectedData = data.requests.map(item => ({
                        month: item.month,
                        count: item.request_closed_count,
                        percentage: item.percentage_of_count,
                    }));
                    break;
                case 'cr':
                    selectedData = data.cr.map(item => ({
                        month: item.month,
                        count: item.cr_closed_count,
                        percentage: item.percentage_of_count,
                    }));
                    break;
                default:
                    selectedData = [];
            }
            setGraphData(selectedData);
            setLoading(false);
        }
    }, [data, dataType]);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <LoaderIcon style={{ height: '50px', width: '50px', borderRightColor: "#046a38", borderWidth: "5px" }} />
            </div>
        );
    }


    return (
        <div>
            {/* <h5 style={{ marginBottom: '15px', }}>{title}</h5> */}
            <h4
                style={{
                    color: '#046a38',
                    textAlign: 'center',
                    marginBottom: '15px',
                }}
            >
                {title}
            </h4>
            <ResponsiveContainer width="90%" height="88%">
                <BarChart
                    width={'100%'}
                    height={500}
                    data={graphData}
                    margin={{
                        top: 15,
                        right: -10,
                        left: 40,
                        bottom: 40,
                    }}
                >
                    {/* <CartesianGrid strokeDasharray="3 3" strokeWidth={1.5} /> */}
                    <XAxis dataKey="month" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} />
                    <YAxis label={{ value: yAxisTitle, angle: -90, position: 'insideLeft', dy: 80 }} />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Legend verticalAlign="bottom" /> */}
                    <Bar dataKey="count" fill="#046a38" label={{ position: 'top', fill: '#000', fontWeight: 'semi-bold' }} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', display: "flex", flexDirection: "column" }}>
                <p className="label">{`${label} = ${payload[0].value} Requests`}</p>
                <p className="desc">{`Percentage: (${payload[0].payload.percentage})`}</p>
            </div>
        );
    }

    return null;
};

export default NetworkTrendBarChart;