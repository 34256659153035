import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import Paper from '@mui/material/Paper';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import generatePDF, { Margin } from 'react-to-pdf';
import './mic-report.css';
import { DarkMode } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { Card, Modal, TextField } from '@mui/material';
import {
    CalendarIcon,
    DesktopDatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SaveButton from '../../components/SubscriptionModal/Mapping/Buttons/Save Button/saveButton';
import { Stack } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { api } from '../../axiosWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoFetch } from '../../redux/userPreferenceFetch';
import { setUserInfo } from '../../redux/userPreferencesSlice';
import {
    useMsal,
} from '@azure/msal-react';
import ListItems from "../MicReport/listItems";
import {
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import NetworkTrendBarChart from './Charts/networkTrendBarChart';
import NetworkPieChart from './Charts/networkPieChart';
import NetworkTopPerformer from './Charts/networkTopPerformer';
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const app_env = process.env.REACT_APP_ENV;
const getUsername = payload => {
    if (app_env === 'local') {
        return payload?.email?.split('@')[0];
    } else {
        return payload[0]?.name?.split(',')[1] ?? payload[0]?.name?.split(',')[0];
    }
};



export default function NetworkDashboard() {
    const pageTitle = 'Weekly Report Dashboard - CMP Network';
    //  Change title of the page
    document.title = pageTitle;
    const { userInfo } = useSelector(state => state.userInfo) || {};
    const { instance, accounts } = useMsal();
    const currentUser = accounts ? accounts[0]?.username : null;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [searchParams] = useSearchParams();
    const current_data = searchParams.get('date');
    const [networkReportData, setNetworkReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [calendarValue, setCalendarValue] = useState(null);
    const [currentDate, setCurrentDate] = React.useState(
        current_data || new Date(),
    );

    const [currentDateMicIncidents, setCurrentDateMicIncidents] = useState(
        current_data || new Date(),
    );

    const [mode, setMode] = useState('light');
    const [showCalendar, setShowCalendar] = useState(true);
    const defaultTheme = createTheme({
        palette: {
            mode: mode, // Either 'light' or 'dark'
        },
    });

    const downloadPDF = () => {
        // you can also pass React refs, e.g. `generatePDF(ref, options)`
        generatePDF(() => document.getElementById('pdf-container'), {
            method: 'save',
            filename: 'network_weekly_report.pdf',
            page: { margin: Margin.MEDIUM },
        });
    };
    const fetchUserData = async () => {
        // Check if a user is logged in.
        setLoading(true);
        try {
            if (accounts && accounts.length > 0) {
                // Get the email of the logged-in user.

                const result = await userInfoFetch(currentUser);

                if (result) {
                    const currentPreference = result?.preference ?? [];
                    result['currentPreference'] = currentPreference;
                    result['username'] = getUsername(accounts);
                    // Call the action to fetch user preferences with the email ID.
                    dispatch(setUserInfo(result));
                } else {
                    // Handle the case where result is undefined or null.
                    console.error('Error: userInfoFetch returned undefined or null.');
                }

                //Check if user has set a default theme and view
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserDataLocal = async () => {
        setLoading(true);
        try {
            const currentUser = process.env.REACT_APP_CURRENT_USER;
            const result = await userInfoFetch(currentUser);

            if (result) {
                const currentPreference = result?.preference ?? [];
                result['currentPreference'] = currentPreference;
                result['username'] = getUsername({ email: currentUser });

                dispatch(setUserInfo(result));
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };
    // Function to toggle between light and dark mode
    const toggleTheme = () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
    };

    const handleDateChange = newValue => {
        setCalendarValue(newValue);
        const newDate = new Date(newValue['$d']);
        newDate.setDate(newDate.getDate() + 1); // Increment the date by 1
        setShowCalendar(false);
        setCurrentDate(newDate);
    };

    const getFormattedDate = date => {
        const datel = new Date(date);

        return `${datel.getMonth() + 1}/${datel.getDate() - 1}/${datel.getFullYear()}`;
    };

    const fetchNetworkData = () => {
        // Use a template string to construct the URL
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/networkingDashboard/live`;
        setCurrentDateMicIncidents(currentDate);
        api
            .get(url)
            .then(res => {
                setNetworkReportData(res.data);
                setOpenCalendar(false);
            })
            .catch(err => {
                console.error('Error fetching MIC Report data:', err); // Improved error logging
            });
    };
    const fetchNetworkDataByDate = currentDate => {
        console.log('fetching data for date:', currentDate);
        setLoading(true);
        setCurrentDateMicIncidents(currentDate);

        const dateParsed = new Date(currentDate).toISOString();
        const datePayload = `${dateParsed.split('T')[0]}T12:30:00.000Z`;
        // Use a template string to construct the URL
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/networkingDashboard/dataByDate`;

        api
            .post(url, { date: datePayload })
            .then(res => {
                if (res.data.reports) {
                    setNetworkReportData(res.data.reports);
                    toast.success(
                        `Showing data for given date ${datePayload?.split('T')[0]}`,
                        {
                            duration: 2000,
                            style: {
                                minWidth: 'fit-content', // Increase the minimum width as needed
                            },
                        },
                    );
                }
                else if (res.data.reports === null) {
                    setNetworkReportData(null);
                    toast.error(
                        `No data found for the given date ${datePayload?.split('T')[0]}. Please select another date.`,
                        {
                            duration: 6000,
                            style: {
                                minWidth: 'fit-content', // Increase the minimum width as needed
                            },
                        },
                    );
                }
            })
            .catch(err => {
                console.error('Error fetching Network Data:', err); // Improved error logging
            })
            .finally(() => {
                setLoading(false);
                setOpenCalendar(false);
            });
    };

    const handleNavigateToHomePage = () => {
        setCurrentDate(new Date());
        navigate('/network-weekly-report');
    };

    // Function to create data format dynamically for breached requests
    const createBreachedRequestsData = data => {
        if (!data?.requests) {
            return [];
        }
        return data.requests.map(item => ({
            name: item.request_sla_breach === 'true' ? 'SLA Missed' : 'SLA Achieved',
            value: parseInt(item.requested_item_count, 10),
            percentage: item.percentage_of_requested_items,
        }));
    };

    // Function to create data format dynamically for breached incidents
    const createBreachedIncidentsData = data => {
        if (!data?.incidents_breached) {
            return [];
        }
        return data.incidents_breached.map(item => ({
            name: item.ticket_sla_breach === 'true' ? 'SLA Missed' : 'SLA Achieved',
            value: parseInt(item.incident_count, 10),
            percentage: item.percentage_of_incidents,
        }));
    };

    // Function to create data format dynamically for breached change requests
    const createBreachedChangeRequestsData = data => {
        if (!data?.cr_breached) {
            return [];
        }
        return data.cr_breached.map(item => ({
            name: item.ticket_sla_breach === 'true' ? 'SLA Missed' : 'SLA Achieved',
            value: parseInt(item.change_request_count, 10),
            percentage: item.percentage_of_change_requests,
        }));
    };

    const requestsData = createBreachedRequestsData(networkReportData?.network_sla_breached_data);
    const incidentsData = createBreachedIncidentsData(networkReportData?.network_sla_breached_data);
    const changeRequestsData = createBreachedChangeRequestsData(networkReportData?.network_sla_breached_data);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleSave = () => {
        // fetchNetworkDataByDate(currentDate);
        const dateParsed = currentDate?.toISOString();
        const dateString = `${dateParsed.split('T')[0]}T12:30:00.000Z`;
        navigate(`/network-weekly-report?date=${dateString}`);
    };

    useEffect(() => {
        if (app_env === 'local') {
            fetchUserDataLocal();
        } else {
            fetchUserData();
        }
    }, [dispatch, instance, accounts]);

    useEffect(() => {
        if (userInfo) {
            if (current_data) {
                console.log('called')
                fetchNetworkDataByDate(current_data);
            } else {
                fetchNetworkData();
            }
        }
    }, [userInfo, current_data]);

    const formatDate = date => {
        return moment(date).tz('America/Chicago').format('MMM D, YYYY');
    };

    if (loading) {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '100vh',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        elevation={0}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            border: '0px solid #f0f0f0',
                            padding: '1rem',
                            height: '300px',
                            borderRadius: '20px',
                        }}
                    >
                        <Typography variant="h4">Loading CMP Network Dashboard...</Typography>
                        {/* <Typography variant="caption" mb={2}>
              Loading | No data found
            </Typography> */}

                        {/* <Link to="/" style={{ width: '100%', textAlign: 'center' }}>
                            Go to service status dashboard
                        </Link> */}
                    </Card>
                </Box>
            </ThemeProvider>
        );
    }

    if (!networkReportData) {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position="absolute" open={open}>
                        <Toolbar
                            sx={{
                                pr: '24px',
                                backgroundColor: 'white',
                                color: 'black',
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1, color: '#046a38', fontWeight: 'bold' }}
                            >
                                {pageTitle}
                            </Typography>
                            <IconButton color="inherit" onClick={() => downloadPDF()}>
                                <DownloadIcon />
                            </IconButton>
                            <IconButton color="inherit" onClick={() => setOpenCalendar(true)} >
                                <CalendarIcon />
                            </IconButton>
                            <IconButton color="inherit" onClick={toggleTheme}>
                                <DarkMode />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open} sx={{
                        '& .MuiDrawer-paper': {
                            width: open ? 'inherit' : '60px'
                        },
                    }}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: theme =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                            <div
                                id="pdf-container"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '100%',
                                                borderRadius: '1.5rem',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography variant="h6" color="textSecondary">
                                                No data found for the given date. Please select another date.
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                component="h1"
                                                align="center"
                                                style={{ color: '#046a38', cursor: 'pointer' }}
                                                onClick={handleNavigateToHomePage}
                                            >
                                                Back to Live Data
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    </Box>
                </Box>
                <Modal
                    open={openCalendar}
                    onClose={() => setOpenCalendar(false)}
                >
                    <Box
                        style={{
                            position: 'absolute',
                            top: '60px',
                            right: '0px',
                            width: '350px',
                            height: '150px',
                            borderRadius: '10px',
                            border: 'none',
                            background: '#FFF',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        <Stack style={{ padding: '1rem' }} gap={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    disableFuture={true}
                                    label="Date"
                                    inputFormat="DD/MM/YYYY"
                                    onChange={handleDateChange}
                                    value={calendarValue}
                                    slots={{
                                        textField: (params) => <TextField {...params} sx={{ margin: 0 }} />
                                    }}
                                />
                            </LocalizationProvider>
                            <SaveButton
                                handleSave={handleSave}
                                mobileStyle={{ color: '#fff', width: '100%' }}
                                text={`Get Data for ${getFormattedDate(currentDate)}`}
                            />
                        </Stack>
                    </Box>
                </Modal>
            </ThemeProvider>
        );
    }

    // if (!userInfo || !userInfo.micAccess) {
    //   return loading ? (
    //     <ThemeProvider theme={defaultTheme}>
    //       <Box sx={{ display: 'flex' }}>
    //         <CssBaseline />
    //       </Box>
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           height: '100vh',
    //           flexDirection: 'column',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //         }}
    //       >
    //         <Card
    //           elevation={0}
    //           sx={{
    //             display: 'flex',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             alignItems: 'flex-start',
    //             border: '0px solid #f0f0f0',
    //             padding: '1rem',
    //             height: '300px',
    //             borderRadius: '20px',
    //           }}
    //         >
    //           <Typography variant="h4">Loading | Please wait</Typography>
    //         </Card>
    //       </Box>
    //     </ThemeProvider>
    //   ) : (
    //     <ThemeProvider theme={defaultTheme}>
    //       <Box sx={{ display: 'flex' }}>
    //         <CssBaseline />
    //       </Box>
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           height: '100vh',
    //           flexDirection: 'column',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //         }}
    //       >
    //         <Card
    //           elevation={0}
    //           sx={{
    //             display: 'flex',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             alignItems: 'flex-start',
    //             border: '0px solid #f0f0f0',
    //             padding: '1rem',
    //             height: '300px',
    //             borderRadius: '20px',
    //           }}
    //         >
    //           <Typography variant="h4">401 Unauthorized | No Access</Typography>
    //           <Typography variant="caption" mb={2}>
    //             Please talk to the administrator of Service Status Dashboard to
    //             enable access for you.
    //           </Typography>

    //           <Link to="/" style={{ width: '100%', textAlign: 'center' }}>
    //             Go to service status dashboard
    //           </Link>
    //         </Card>
    //       </Box>
    //     </ThemeProvider>
    //   );
    // }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px',
                            backgroundColor: 'white',
                            color: 'black',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1, color: '#046a38', fontWeight: 'bold' }}
                        >
                            {pageTitle}
                        </Typography>
                        <IconButton color="inherit" onClick={() => downloadPDF()}>
                            <DownloadIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={() => setOpenCalendar(true)} >
                            <CalendarIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={toggleTheme}>
                            <DarkMode />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open} sx={{
                    '& .MuiDrawer-paper': {
                        width: open ? 'inherit' : '60px'
                    },
                }}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <ListItems selectedType={"NETWORK"} open={open} />
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                        <div
                            id="pdf-container"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                            }}
                        >
                            <Typography
                                variant="caption"
                                component="p"
                                gutterBottom
                                align="center"
                                style={{ color: 'red', fontSize: '0.75rem' }}
                            >
                                * Showing data for {formatDate(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 7)))} to {formatDate(currentDate)}
                            </Typography>

                            <Grid container spacing={3}>
                                {/* Info */}

                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 200,
                                            borderRadius: '1.5rem',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            className={
                                                mode === 'light'
                                                    ? 'metric-numbers'
                                                    : 'metric-numbers-dark'
                                            }
                                        >
                                            {networkReportData?.current_data?.requests?.active}
                                        </label>
                                        <label
                                            className={
                                                mode === 'light' ? 'tile-label' : 'tile-label-dark'
                                            }
                                        >
                                            RITM Active{' '}
                                        </label>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 200,
                                            borderRadius: '1.5rem',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            className={
                                                mode === 'light'
                                                    ? 'metric-numbers'
                                                    : 'metric-numbers-dark'
                                            }
                                        >
                                            {networkReportData?.current_data?.requests?.closed}
                                        </label>
                                        <label
                                            className={
                                                mode === 'light' ? 'tile-label' : 'tile-label-dark'
                                            }
                                        >
                                            RITM Closed{' '}
                                        </label>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 200,
                                            borderRadius: '1.5rem',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            className={
                                                mode === 'light'
                                                    ? 'metric-numbers'
                                                    : 'metric-numbers-dark'
                                            }
                                        >
                                            {networkReportData?.current_data?.incidents?.active}
                                        </label>
                                        <label
                                            className={
                                                mode === 'light' ? 'tile-label' : 'tile-label-dark'
                                            }
                                        >
                                            INC Active{' '}
                                        </label>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 200,
                                            borderRadius: '1.5rem',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            className={
                                                mode === 'light'
                                                    ? 'metric-numbers'
                                                    : 'metric-numbers-dark'
                                            }
                                        >
                                            {networkReportData?.current_data?.incidents?.closed}
                                        </label>
                                        <label
                                            className={
                                                mode === 'light' ? 'tile-label' : 'tile-label-dark'
                                            }
                                        >
                                            INC Closed{' '}
                                        </label>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 200,
                                            borderRadius: '1.5rem',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            className={
                                                mode === 'light'
                                                    ? 'metric-numbers'
                                                    : 'metric-numbers-dark'
                                            }
                                        >
                                            {networkReportData?.current_data?.change_requests?.active}
                                        </label>
                                        <label
                                            className={
                                                mode === 'light' ? 'tile-label' : 'tile-label-dark'
                                            }
                                        >
                                            {' '}
                                            CR Active
                                        </label>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 200,
                                            borderRadius: '1.5rem',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            className={
                                                mode === 'light'
                                                    ? 'metric-numbers'
                                                    : 'metric-numbers-dark'
                                            }
                                        >
                                            {networkReportData?.current_data?.change_requests?.closed}
                                        </label>
                                        <label
                                            className={
                                                mode === 'light' ? 'tile-label' : 'tile-label-dark'
                                            }
                                        >
                                            CR Closed{' '}
                                        </label>
                                    </Paper>
                                </Grid>
                                {/* Monthly Closed Requests Trend*/}
                                <Grid item xs={12} md={8} lg={6}>
                                    <Paper
                                        sx={{
                                            p: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            borderRadius: '1.5rem',
                                            minHeight: '300px',
                                        }}
                                    >

                                        <NetworkTrendBarChart
                                            title={`Monthly Closed Requests Trend`}
                                            data={networkReportData?.monthly_closed_trend_data}
                                            dataType={'requests'}
                                            yAxisTitle={'Requested Item Count'}
                                        />
                                    </Paper>
                                </Grid>


                                {/* Monthly Closed Requests Trend*/}
                                <Grid item xs={12} md={8} lg={6}>
                                    <Paper
                                        sx={{
                                            p: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            borderRadius: '1.5rem',
                                            minHeight: '300px',
                                        }}
                                    >

                                        <NetworkTrendBarChart
                                            title={`Monthly Closed Incidents Trend`}
                                            data={networkReportData?.monthly_closed_trend_data}
                                            dataType={'incidents'}
                                            yAxisTitle={'Incident Count'}
                                        />
                                    </Paper>
                                </Grid>

                                {/* Monthly Closed Change Requests Trend*/}
                                {/* <Grid item xs={12} md={8} lg={8}>
                                    <Paper
                                        sx={{
                                            p: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            borderRadius: '1.5rem',
                                            minHeight: '300px',
                                        }}
                                    >

                                        <NetworkTrendBarChart
                                            title={`Monthly Closed CR Trend`}
                                            data={networkReportData?.monthly_closed_trend_data}
                                            yAxisTitle={'Change Request Count'}
                                            dataType={'cr'}
                                        />
                                    </Paper>
                                </Grid> */}
                                {/* Requests SLA */}
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            borderRadius: '1.5rem',
                                            minHeight: '300px',
                                        }}
                                    >
                                        <NetworkPieChart
                                            title={'Requests SLA Breach'}
                                            data={requestsData}
                                            dataType="request_sla_breach"
                                        />
                                    </Paper>
                                </Grid>
                                {/* Incidents SLA */}
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            borderRadius: '1.5rem',
                                            minHeight: '300px',
                                        }}
                                    >
                                        <NetworkPieChart
                                            title={'Incidents SLA Breach'}
                                            data={incidentsData}
                                            dataType="ticket_sla_breach"
                                        />
                                    </Paper>
                                </Grid>
                                {/* Change Requests SLA */}
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            borderRadius: '1.5rem',
                                            minHeight: '300px',
                                        }}
                                    >
                                        <NetworkPieChart
                                            title={'Change Requests SLA Breach'}
                                            data={changeRequestsData}
                                            dataType="ticket_sla_breach"
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minHeight: '300px',
                                            borderRadius: '1.5rem',
                                        }}
                                    >
                                        <NetworkTopPerformer
                                            data={networkReportData?.accomplishment}
                                        />
                                        <Modal
                                            open={openCalendar}
                                            onClose={() => setOpenCalendar(false)}
                                        >
                                            <Box
                                                style={{
                                                    position: 'absolute',
                                                    top: '60px',
                                                    right: '0px',
                                                    // transform: 'translate(-50%, -50%)',

                                                    width: '350px',
                                                    height: '150px',
                                                    borderRadius: '10px',
                                                    border: 'none',
                                                    background: '#FFF',
                                                    boxShadow:
                                                        ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                                }}
                                            >
                                                <Stack style={{ padding: '1rem' }} gap={4}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopDatePicker
                                                            disableFuture={true}
                                                            label="Date "
                                                            inputFormat="DD/MM/YYYY" // The date format here
                                                            onChange={handleDateChange}
                                                            renderInput={params => <TextField {...params} />}
                                                            sx={{ margin: 0 }}
                                                            value={calendarValue}
                                                        />
                                                    </LocalizationProvider>
                                                    <SaveButton
                                                        handleSave={handleSave}
                                                        mobileStyle={{ color: '#fff', width: '100%' }}
                                                        text={`Get Data for ${getFormattedDate(currentDate)}`}
                                                    />{' '}
                                                </Stack>
                                            </Box>
                                        </Modal>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider >
    );
}
