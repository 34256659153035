import React from 'react';
import './mic-report.css';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import LoginPageMic from '../Home/LoginPageMic';
import { Container } from '@mui/material';
import NetworkDashboard from './networkDashboard';

export default function NetworkReportComponent() {
    const pageTitle = 'Weekly Report Dashboard - CMP Network';

    document.title = pageTitle;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <AuthenticatedTemplate>
                <NetworkDashboard />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div>
                    <Container
                        maxWidth={false}
                        style={{
                            display: 'flex',
                            height: '100vh',
                            flexDirection: 'row',
                            padding: 'none',
                        }}
                        className="homePageContainer"
                        data-testid="login-page"
                    >
                        <LoginPageMic />
                    </Container>
                </div>
            </UnauthenticatedTemplate>
        </div>
    );
}
