import React from 'react';
import Container from '@mui/material/Container';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import MicReportDashboard from './micdashboard';
import LoginPageMic from '../Home/LoginPageMic';

export default function MicReportComponent() {
  const pageTitle = 'Weekly Report Dashboard - Platform Operations';
  //  Change title of the page
  document.title = pageTitle;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <AuthenticatedTemplate>
        <MicReportDashboard />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>
          <Container
            maxWidth={false}
            style={{
              display: 'flex',
              height: '100vh',
              flexDirection: 'row',
              padding: 'none',
            }}
            className="homePageContainer"
            data-testid="login-page"
          >
            {window.innerWidth > 770 ? <LoginPageMic /> : <LoginPageMic />}
          </Container>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
}
