import * as React from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PublicIcon from '@mui/icons-material/Public';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';

import { Divider, List, Tooltip } from '@mui/material';

export default function ListItems({ selectedType, open }) {

  const navigate = useNavigate();
  return (
    <>
      {selectedType === 'OPS' &&
        <List component="nav" sx={{ paddingTop: 0 }}>
          <Tooltip title="Platform OPS Dashboard" arrow>
            <ListItemButton onClick={() => navigate('/weekly-report')} sx={{
              backgroundColor: "#046a38", color: "white", '&:hover': { color: '#048948' }
            }}>
              <ListItemIcon sx={{ color: "inherit", '&:hover': { color: 'inherit' }, minWidth: open ? '40px' : '56px' }}>
                <EngineeringIcon />
              </ListItemIcon>
              <ListItemText primary="OPS Dashboard" />
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Networking Dashboard" arrow>
            <ListItemButton onClick={() => navigate('/network-weekly-report')} >
              <ListItemIcon sx={{ minWidth: open ? '40px' : '56px' }} >
                <PublicIcon />
              </ListItemIcon>
              <ListItemText primary="Networking Dashboard" />
            </ListItemButton>
          </Tooltip>
          <Divider sx={{ my: 1 }} />
        </List>
      }
      {selectedType === 'NETWORK' &&
        <List component="nav" sx={{ paddingTop: 0 }}>
          <Tooltip title="Platform OPS Dashboard" arrow>
            <ListItemButton onClick={() => navigate('/weekly-report')} >
              <ListItemIcon sx={{ minWidth: open ? '40px' : '56px' }} >
                <EngineeringIcon />
              </ListItemIcon>
              <ListItemText primary="OPS Dashboard" />
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Networking Dashboard" arrow>
            <ListItemButton onClick={() => navigate('/network-weekly-report')} sx={{
              backgroundColor: "#046a38", color: "white", '&:hover': { color: '#048948' }
            }}>
              <ListItemIcon sx={{ color: "inherit", '&:hover': { color: 'inherit' }, minWidth: open ? '40px' : '56px' }}>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText primary="Networking Dashboard" />
            </ListItemButton>
          </Tooltip>
          <Divider sx={{ my: 1 }} />
        </List>
      }
    </>
  )
}
