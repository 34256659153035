import * as React from 'react';
import { useEffect } from 'react';

export default function NetworkTopPerformer({ data }) {
    const [loading, setLoading] = React.useState(true);
    const [editorContent, setEditorContent] = React.useState('');

    useEffect(() => {
        // Simulate data fetching
        const fetchData = async data => {
            if (!data) {
                return;
            }

            setEditorContent(data);
            setLoading(false);
        };

        fetchData(data);
    }, [data]);

    if (loading) {
        return (
            <div className="loading-container">
                <div className="spinner"></div>
            </div>
        ); // Display a loading indicator
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <h4 style={{ color: '#046a38' }}>Major Accomplishments </h4>
            </div>
            <div dangerouslySetInnerHTML={{ __html: editorContent }} />
        </div>
    );
}
